import { Button } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"

import { useAcceptRelationship } from "@/hooks/relationships"
import { Column, Row } from "../styled_layout"
import { LooserBodyText } from "../styled_text"
import { ruminatiColors } from "@/utilities/colors"
import { useAuthStore } from "@/state/auth_store"

export default function RelationshipUserInvitedToOrgTo (props: {
  relationshipId: string
  orgName: string
}) {
  const acceptRelationship = useAcceptRelationship()
  const authStore = useAuthStore()
  const user = authStore.user
  if (!user) return

  const org = user.organisations?.find(o => o.name === props.orgName)

  return <>
  <Row style={{
    width: '100%',
    justifyContent: 'flex-start',
  }}>
    <Column style={{
      flexGrow: 1,
      alignItems: 'flex-start'
      }}>
      <LooserBodyText style={{
        color: ruminatiColors.green_4
      }}>
         You've been invited to help {props.orgName} with their emissions data.
         <br/><br/>
         {!org && 
            <Button 
            type="primary" 
            size="small" 
            ghost
            loading={!acceptRelationship.isIdle}
            onClick={async () => {
              if (!authStore.user) return
              await acceptRelationship.mutateAsync({
                relationshipId: props.relationshipId,
                recipientId: authStore.user.uid
              })
            }}
            >
              Accept
            </Button>
         } 
         {org && 
          <LooserBodyText>
            <CheckCircleOutlined style={{marginRight: '10px'}}/>
            Actioned
          </LooserBodyText>
         }
      </LooserBodyText>
    </Column>
  </Row>
</>
}