import { InfoCard } from "../card";
import { Row, Column } from "../styled_layout";
import { Form, FormData } from "../../models/form/form";
import { DropdownFormField, EmailFormField } from "@/models/form/form_fields/basic_form_fields";

import { BodyText } from "../styled_text";
import { ruminatiColors } from "@/utilities/colors";
import { AccessManagementPopupId } from "@/screens/organisation/member_tab_farm_org"
import { usePopupStore, PopupState } from "@/state/popup_store";
import { RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";
import { ResourceTypeOrganisationTB } from "@ruminati/types/resource";
import { getCurrentOrganisation, getCurrentOrganisationId } from "@/utilities/organisations";
import { useAuthStore } from "@/state/auth_store";
import { OrganisationTypeRecordsTB } from "@ruminati/types/organisation";

// import {
//   AvailableRole,
// } from "../access/member_row";
import {
  PARTNER_ORG_ROLES,
  INTERNAL_FARM_ORG_ROLES
 } from "@ruminati/permissions/roles";
import { useCreateRelationship } from "@/hooks/relationships";
import { RoleT } from "@ruminati/types/role";

export interface AddMemberDTO {
  email: string;
  roleId: string
}

const memberForm = (popupStore: PopupState, roles: RoleT[]): FormData<AddMemberDTO> => ({
  fields: {
    email: new EmailFormField({
      placeholder: "Email address",
      required: true
    }),
    roleId: new DropdownFormField({
      placeholder: "Default Role",
      children: () => {
        return <BodyText
          onClick={(() => {
            popupStore.addPopup(AccessManagementPopupId.RoleDescriptionPopup)
          })}
          style={{
            cursor: 'pointer',
            borderBottom: `1px solid ${ruminatiColors.dark_green}`
          }}>Find out more about roles</BodyText>
      },
      options: roles.map(r => {
        return {
          value: r.id,
          name: r.name
        }
      }),
      required: true,
    }),
  }
})

export default function AddOrgMemberPopup(props: {
  onSubmit: () => void
}) {
  const currentOrgId = getCurrentOrganisationId()
  if (!currentOrgId) return

  const authStore = useAuthStore()
  const popupStore = usePopupStore()

  const currentOrg = getCurrentOrganisation(authStore.user!);

  const availableRoles = currentOrg?.type === OrganisationTypeRecordsTB.const ? PARTNER_ORG_ROLES : INTERNAL_FARM_ORG_ROLES

  const {
    mutateAsync: createRelationshipMutateAsync,
    isPending: createOrgInvitationIsPending
  } = useCreateRelationship()

  return (<Column style={{
    padding: '20px 40px',
    width: '760px'
  }}>
    <InfoCard
      content={`Invite someone to join your Organisation - they don't need to have an existing Ruminati account.`}
    />
    <Row style={{ height: '20px' }}></Row>
    <Row>
      <Form
        data={memberForm(popupStore, availableRoles)}
        width={"400px"}
        initialValue={{}}
        fieldSize="small"
        submitText={`Invite`}
        buttonSize="medium"
        disableSubmitButton={createOrgInvitationIsPending}
        onSubmit={async (value) => {
          if (value) {
            const newMember = await createRelationshipMutateAsync({
              recipientType: RelationshipRecipientTypeUserTB.const,
              invitedEmail: value.email,
              email: value.email,
              isPending: true,
              roleId: value.roleId,
              resourceId: currentOrgId,
              resourceType: ResourceTypeOrganisationTB.const,
              revokedPermissions: false
            })
            if (newMember) props.onSubmit()
          }
        }}
      />
    </Row>
  </Column>
  );
}
