import { UserClientT, UserMemberOfOrganisationT } from "@ruminati/types/user";
import { USERS_CURRENT_ORG_ID_COOKIE_FOR_ENV } from "@ruminati/constants"
import { OrganisationT } from "@ruminati/types/organisation";
import { RelationshipRecipientTypeUserTB } from "@ruminati/types/relationship";

import { getCookies } from "./cookies";
import { getEnvFromUrl } from "./env"

const cookieEnv = getEnvFromUrl()
export const CURRENT_ORGANISATION_ID_COOKIE_KEY = USERS_CURRENT_ORG_ID_COOKIE_FOR_ENV(cookieEnv)

export function getCurrentOrganisationId (): string | undefined {
  const cookies = getCookies()
  return cookies[CURRENT_ORGANISATION_ID_COOKIE_KEY]
}

export function getCurrentOrganisation(user: UserClientT): OrganisationT | undefined {
  const cookies = getCookies()
  if (user.organisations.length > 0 && cookies[CURRENT_ORGANISATION_ID_COOKIE_KEY]) {
    return user.organisations.find(o => o.id === cookies[CURRENT_ORGANISATION_ID_COOKIE_KEY])
  } else {
    return undefined
  }
}

export function isPrimaryOganisation(user: UserClientT, organisation: OrganisationT): boolean {
  const primaryOrgId = user.userPreferences?.primaryOrganisationId
  if (!primaryOrgId) return false
  const primaryOrg = getPrimaryOrganisation(user)
  return primaryOrg?.id === organisation.id
}

export function getPrimaryOrganisation (user: UserClientT): OrganisationT | undefined {
  const primaryOrgId = user.userPreferences?.primaryOrganisationId
  if (!primaryOrgId) return undefined
  return user.organisations.find(o => o.id === primaryOrgId)
}

export function getUserOrgMembers (orgMembers: UserMemberOfOrganisationT[]): UserMemberOfOrganisationT[] {
  return orgMembers.filter(o => o.recipientType === RelationshipRecipientTypeUserTB.const)
}
