import { useSearchParams } from "react-router-dom";

import queryClient from "@/utilities/queryClient"
import { setCookie } from "@/utilities/cookies"

import { usePartnerStore } from "@/state/partners"
import { useDatabaseStore } from "@/state/database_store"
import { useIntegrationsStore } from "@/state/integrations"

import { CURRENT_ORGANISATION_ID_COOKIE_KEY } from "@/utilities/organisations"
import useUserProfile from "./useUserProfile";

export default function useOrgSwitcher() {
  const partnerStore = usePartnerStore()
  const databaseStore = useDatabaseStore()
  const integrationsStore = useIntegrationsStore()
  const userProfile = useUserProfile()
  const [searchParams, setSearchParams] = useSearchParams()

  // TODO: not ideal to have this function as async? makes flow control by the client harder...
  // would be nice if the user data was also in tanstack-query cache... and we could just similarly invalidate, along with the properties etc below
  async function changeOrganisation (newOrgId: string){
    // set new current org
    setCookie(CURRENT_ORGANISATION_ID_COOKIE_KEY, newOrgId)

    await userProfile.refreshProfile()

    // Delete out the tab param in case
    // the new Org context doesn't have that tab
    if (searchParams.has('tab')) {
      searchParams.delete('tab')
      setSearchParams(searchParams)
    }

    // clear out zustand stores that may contain org-specific entity data
    databaseStore.clear()
    partnerStore.clear()
    integrationsStore.clear()

    queryClient.invalidateQueries({ queryKey: ['org_members'] })
    queryClient.invalidateQueries({ queryKey: ['org_clients'] })
    queryClient.invalidateQueries({ queryKey: ['properties'] })
  }

  return {
    changeOrganisation
  }
}
